import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Header from "./common/Header";
import UserContext from "../helper/UserContext";
import moment from "moment";
import { Backdrop, CircularProgress } from "@mui/material/";
import LoadingInfo from "./common/LoadingInfo";
import EditBio from "./common/EditBio";
import Message from "./common/Message";
let qualificationList = [
  {
    id: 0,
    selected: false,
    qualification: "None",
  },
  {
    id: 1,
    selected: false,
    qualification: "QTS",
  },
  {
    id: 2,
    selected: false,
    qualification: "PGCE",
  },
  {
    id: 3,
    selected: false,
    qualification: "EYTS",
  },
  {
    id: 4,
    selected: false,
    qualification: "Childcare Level 3s",
  },
  {
    id: 5,
    selected: false,
    qualification: `Bachelor's Degree`,
  },
  {
    id: 6,
    selected: false,
    qualification: `Master's Degree`,
  },
  { 
    id: 7, 
    selected: false, 
    qualification: `Student` 
  },
];

class NannyDetails extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      nannyDetails: [],
      reviews: [],
      skills: [],
      specialSkills: [],
      services: [],
      isModalOpen: false,
      message: "",
      editMode: false,
      isSuccess: false,
      locations: [],
      languageList: [],
      serviceList: [],
      showInfo: false,
      teacherQualification: "None",
      allQualifications: [
        {
          id: 0,
          selected: false,
          qualification: "None",
        },
        {
          id: 1,
          selected: false,
          qualification: "QTS",
        },
        {
          id: 2,
          selected: false,
          qualification: "PGCE",
        },
        {
          id: 3,
          selected: false,
          qualification: "EYTS",
        },
        {
          id: 4,
          selected: false,
          qualification: "Childcare Level 3s",
        },
        {
          id: 5,
          selected: false,
          qualification: `Bachelor's Degree`,
        },
        {
          id: 6,
          selected: false,
          qualification: `Master's Degree`,
        },
        { 
          id: 7, 
          selected: false, 
          qualification: `Student` 
        },
      ],
    };
  }
  async componentDidMount() {
    await this.getNannyDetails();
    //await this.getAllServices()
  }
  componentWillUnmount(){
    console.log("unmounting")
    this.setState({
      isLoading: false,
      nannyDetails: [],
      reviews: [],
      skills: [],
      specialSkills: [],
      services: [],
      isModalOpen: false,
      message: "",
      editMode: false,
      isSuccess: false,
      locations: [],
      languageList: [],
      serviceList: [],
      showInfo: false,
      teacherQualification: "None",
      allQualifications: [
        {
          id: 0,
          selected: false,
          qualification: "None",
        },
        {
          id: 1,
          selected: false,
          qualification: "QTS",
        },
        {
          id: 2,
          selected: false,
          qualification: "PGCE",
        },
        {
          id: 3,
          selected: false,
          qualification: "EYTS",
        },
        {
          id: 4,
          selected: false,
          qualification: "Childcare Level 3s",
        },
        {
          id: 5,
          selected: false,
          qualification: `Bachelor's Degree`,
        },
        {
          id: 6,
          selected: false,
          qualification: `Master's Degree`,
        },
        { 
          id: 7, 
          selected: false, 
          qualification: `Student` 
        },
      ],
    });
  }
  async getNannyDetails() {
    this.setState({ isLoading: true });
    let nanny_id = this.props.match.params._id;
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/getNanny?nanny_id=${nanny_id}`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const nanny = await axios.get(url, config);
      let allservices = await this.getAllServices();
      console.log(allservices);
      console.log(nanny);
      let _allServices = [];
      if (nanny.status == 200) {
        if (
          nanny.data.nannyDetail.qualifications != null &&
          nanny.data.nannyDetail.qualifications.length > 0
        ) {
          let _tmpQualifications = this.state.allQualifications;
          nanny.data.nannyDetail.qualifications.map((q) => {
            let index = this.state.allQualifications.findIndex(
              (_q) => _q.qualification == q
            );
            _tmpQualifications[index] = {
              ..._tmpQualifications[index],
              selected: true,
            };
          });

          this.setState({
            allQualifications: _tmpQualifications,
          });
        }
        let _teacherServices = nanny.data.nannyDetail.services;
        allservices.map((s) => {
          let _teacherService = _teacherServices.filter(
            (_s) => _s.service_id == s.service_id
          );
          let _service = {
            service_id: s.service_id,
            service_name: s.service_name,
            price: _teacherService.length > 0 ? _teacherService[0].price : "0",
            selected: _teacherService.length > 0 ? true : false,
          };
          _allServices.push(_service);
        });
        this.setState({
          nannyDetails: nanny.data.nannyDetail,
          languageList: nanny.data.nannyDetail.languageList,
          skills: nanny.data.nannyDetail.skills,
          services: _allServices,
          locations: nanny.data.nannyDetail.locations,
          isLoading: false,
          specialSkills: nanny.data.nannyDetail.specialSkills,
          teacherQualification:
            nanny.data.nannyDetail.qualification != null
              ? nanny.data.nannyDetail.qualification
              : "None",
        });
      } else {
        console.log("something went wrong!");
      }
    } catch (err) {
      console.log(err);
    }
  }
  getAllServices = async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/services/getAllServices`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let services = await axios.get(url, config);

      return services.data.AllServices;
    } catch (err) {
      console.log(err);
    }
  };
  goBack = () => {
    this.props.history.goBack();
  };
  changeStatus = async () => {
    this.setState({ isModalOpen: true, message: "Please wait..." });
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/changeStatus`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let params = {
        nanny_id: this.state.nannyDetails.nannyID,
        approved: !this.state.nannyDetails.approved,
      };
      const status = await axios.post(url, params, config);
      if (status.status == 200) {
        this.setState({
          message: `Nanny's status changed.`,
        });
        setTimeout(() => {
          this.setState({
            isModalOpen: false,
          });
          this.props.history.goBack();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  cancelAcount = async () => {
    this.setState({ isModalOpen: true, message: "Please wait..." });
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/cancelAcount`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let params = {
        nanny_id: this.state.nannyDetails.nannyID,
        approved: !this.state.nannyDetails.approved,
      };
      const status = await axios.post(url, params, config);
      if (status.status == 200) {
        this.setState({
          message: `Theacher's account has been cancelled.`,
        });
        setTimeout(() => {
          this.setState({
            isModalOpen: false,
          });
          this.props.history.goBack();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };
  editBio = async (_bio) => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/editBio`;
      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let params = {
        nanny_id: this.state.nannyDetails.nannyID,
        bio: _bio,
      };
      const nannyBio = await axios.post(url, params, config);
      if (nannyBio.status == 200) {
        this.setState({
          nannyDetails: { ...this.state.nannyDetails, bio: _bio },
          isSuccess: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  closeEdit = () => {
    this.setState({
      editMode: false,
    });
  };
  handleService = (_id) => {
    let index = this.state.services.findIndex((s) => s.service_id == _id);
    let services = this.state.services;
    services[index] = {
      ...services[index],
      selected: !services[index].selected,
    };
    this.setState({ services: services });
  };
  handleQualification = (qName) => {
    let _q = this.state.allQualifications;
    let index = _q.findIndex((q) => q.qualification == qName);
    _q[index] = { ..._q[index], selected: !_q[index].selected };
    this.setState({
      allQualifications: _q,
    });
  };
  updateTeacherServices = async () => {
    try {
      let nanny_id = this.props.match.params._id;
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/updateServices`;

      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let _tmpServices = "";
      this.state.services.map((s) => {
        if (s.selected) {
          _tmpServices = _tmpServices + s.service_id + ",";
        }
      });
      let params = {
        nanny_id: nanny_id,
        services: _tmpServices,
      };
      const updateService = await axios.post(url, params, config);
      if (updateService.status == 200) {
        this.setState({
          message: "Teachers services have been updated!",
          showInfo: true,
        });
      }
    } catch (err) {
      this.setState({
        showInfo: true,
        message: "Something went wrong! Please try again later.",
      });
      console.log(err);
    }
  };
  updateTeacherQualification = async () => {
    try {
      let nanny_id = this.props.match.params._id;
      let url = `${process.env.REACT_APP_BASE_API_URL}/management/nannies/updateQualification`;

      let config = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let _tmpQualifications = "";
      this.state.allQualifications.map((q) => {
        if (q.selected) {
          _tmpQualifications = _tmpQualifications + q.qualification + ",";
        }
      });
      let params = {
        nanny_id: nanny_id,
        qualifications: _tmpQualifications,
      };
      const updateService = await axios.post(url, params, config);
      if (updateService.status == 200) {
        this.setState({
          message: "Teachers qualification has been updated!",
          showInfo: true,
        });
      }
    } catch (err) {
      this.setState({
        showInfo: true,
        message: "Something went wrong! Please try again later.",
      });
      console.log(err);
    }
  };
  closeMessage = async () => {
    this.setState({ showInfo: false });
    await this.getNannyDetails();
  };

  render() {
    return this.state.isLoading ? (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.state.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : (
      <div className="contentContainer">
        <Header
          header={"Teachers"}
          user={this.context.user}
          action={this.openReferenceModal}
          showBack={true}
          buttonTitle={"Invite New Nanny"}
        />
        <div className="clientDetailContainer">
          <div className="infoContainer nannyPersonal">
            <span className="title">Personal Information</span>
            <img src={this.state.nannyDetails.profile_image} />
            <div className="info">
              <span className="name">{this.state.nannyDetails.name}</span>
              <span>{this.state.nannyDetails.phone}</span>
              <span>{this.state.nannyDetails.email}</span>
            </div>
          </div>
          <div className="infoContainer nannyBioContainer">
            <span className="title">Bio</span>
            <span className="nannyBio" style={{ whiteSpace: "pre-wrap" }}>
              {this.state.nannyDetails.bio}
            </span>
            <span className="nannyBio">
              <span style={{ paddingLeft: 0 }}>Experience: </span>
              {this.state.nannyDetails.experience}
            </span>
            <span className="nannyBio">
              <span style={{ paddingLeft: 0 }}>Languages: </span>
              {this.state.languageList?.map((l) => l + ", ")}
            </span>
            <span className="nannyBio">
              <span style={{ paddingLeft: 0 }}>Locations: </span>
              {this.state.locations.map((l) => l.location_name + ", ")}
            </span>
            <a
              onClick={() => {
                this.setState({ editMode: true });
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: 20,
                top: -20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                width: 100,
                height: 40,
                textDecoration: "none",
                color: "#000",
              }}
            >
              <img src={require("../assets/Edit.png")} width={24} height={24} />
              <span className="editBio">Edit Bio</span>
            </a>
          </div>
          <div className="infoContainer bioFix reviews">
            <span className="title">Services</span>
            {this.state.services.map((service) => (
              <div
                className="reviewsItem serviceFix"
                onClick={() => {
                  this.handleService(service.service_id);
                }}
              >
                <div className="nameHolder">
                  <img
                    src={
                      service.selected
                        ? require("../assets/Approve.png")
                        : require("../assets/passive.png")
                    }
                    style={{ width: 30, height: 30 }}
                  />
                  <span className="reviewOwner">
                    {service.service_name}
                    <br />{" "}
                    <span style={{ fontSize: 15 }}>
                      {service.service_name == "Holiday Teachers"
                        ? ""
                        : `(£${service.price}`}
                      {service.service_name == "Weekends"
                        ? " per/hour)"
                        : service.service_name == "Specialist Teachers"
                        ? " per/hour)"
                        : service.service_name == "Holiday Teachers"
                        ? ""
                        : " per/hour)"}
                    </span>
                  </span>
                </div>
              </div>
            ))}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                paddingBottom: 20,
              }}
            >
              <button
                type="submit"
                name="approve"
                id="approve"
                className="saveButton"
                onClick={() => {
                  this.updateTeacherServices();
                }}
              >
                Update Services
              </button>
            </div>
          </div>
          <div className="infoContainer bioFix reviews">
            <span className="title">Qualification</span>
            {this.state.allQualifications.map((q) => (
              <div
                className="reviewsItem serviceFix"
                onClick={() => {
                  this.handleQualification(q.qualification);
                }}
              >
                <div className="nameHolder">
                  <img
                    src={
                      q.selected
                        ? require("../assets/Approve.png")
                        : require("../assets/passive.png")
                    }
                    style={{ width: 30, height: 30 }}
                  />
                  <span className="reviewOwner">{q.qualification}</span>
                </div>
              </div>
            ))}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                paddingBottom: 20,
              }}
            >
              <button
                type="submit"
                name="approve"
                id="approve"
                className="saveButton"
                onClick={() => {
                  this.updateTeacherQualification();
                }}
              >
                Update Qualification
              </button>
            </div>
          </div>
          <div className="infoContainer reviews">
            <span className="title">Skills</span>
            {this.state.skills.map((skill) => (
              <div className="reviewsItem">
                <div className="nameHolder">
                  <img
                    src={require("../assets/Approve.png")}
                    style={{ width: 30, height: 30 }}
                  />
                  <span className="reviewOwner">{skill}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="infoContainer reviews">
            <span className="title">Specialist Subjects</span>
            {this.state.specialSkills.map((skill) => (
              <div className="reviewsItem">
                <div className="nameHolder">
                  <img
                    src={require("../assets/Approve.png")}
                    style={{ width: 30, height: 30 }}
                  />
                  <span className="reviewOwner">{skill}</span>
                </div>
              </div>
            ))}
            {this.state.nannyDetails.otherSpecialSkills != null ? (
              <div className="reviewsItem">
                <div className="nameHolder">
                  <img
                    src={require("../assets/Approve.png")}
                    style={{ width: 30, height: 30 }}
                  />
                  <span className="reviewOwner">
                    {this.state.nannyDetails.otherSpecialSkills}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
          <div style={{ height: 100, alignItems: "center" }}>
            <button
              type="submit"
              name="approve"
              id="approve"
              className="saveButton"
              onClick={() => {
                this.changeStatus();
              }}
            >
              {this.state.nannyDetails.approved ? `Suspend` : `Approve`}
            </button>
            <button
              type="submit"
              name="cancel"
              id="cancel"
              className="saveButton"
              onClick={() => {
                this.cancelAcount();
              }}
            >
              {"Cancel"}
            </button>
          </div>
        </div>
        <EditBio
          open={this.state.editMode}
          editBio={this.editBio}
          close={this.closeEdit}
          isSuccess={this.state.isSuccess}
          bio={this.state.nannyDetails.bio}
        />
        <LoadingInfo
          title={"Approve nanny"}
          message={this.state.message}
          open={this.state.isModalOpen}
          close={this.closeModal}
        />
        <Message
          message={this.state.message}
          close={this.closeMessage}
          open={this.state.showInfo}
        />
      </div>
    );
  }
}
export default withRouter(NannyDetails);
