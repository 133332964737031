import React, { Component } from 'react'
import axios from 'axios'
import { withRouter, Link } from 'react-router-dom'
import Header from './common/Header'
import UserContext from '../helper/UserContext'
import moment from 'moment' 
import {Backdrop, CircularProgress} from '@mui/material/';
import MembershipModal from './common/MembershipModal'

class ClientDetails extends Component {
    static contextType = UserContext
    constructor(props){
        super(props)
        this.state={
            isLoading:false,
            clientInfo:[],
            bookings:[],
            address:null,
            showMembershipModal:false,
            hasError:false,
            message:'',
            isSuccess:false
        }
    }
    async componentDidMount(){
        await this.getClientDetails()
    }
    async getClientDetails (){
        this.setState({isLoading:true})
        let client_id = this.props.match.params._id
        try{
            let url =`${process.env.REACT_APP_BASE_API_URL}/management/clients/clientDetail?client_id=${client_id}`
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            const client = await axios.get(url,config)
            console.log(client)
            if(client.status==200){
                this.setState({
                    clientInfo:client.data.client,
                    bookings:client.data.client.bookings && client.data.client.bookings.length > 0 ? client.data.client.bookings.sort((a,b)=> new Date(b.startTime) - new Date(a.startTime)) : [],
                    address:client.data.client.addresses[0],
                    isLoading:false
                })
            } else {
                console.log('something went wrong!')
            }
        }catch(err){
            console.log(err)
        }
    }
    goBack = () =>{
        this.props.history.goBack()
    }
    changeActiveStatus = async () =>{
        this.setState({isModalOpen:true, message:'Please wait...'})
        try{
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/changeActiveStatus`
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            let params = {
                client_id:this.props.match.params._id,
            }
            if(this.state.clientInfo.status == 'Active'){
                params.status = 'Cancelled'
            } else {
                params.status = 'Active'
            }
            const status = await axios.post(url,params,config)
            if(status.status == 200){
                this.setState({
                    message:status.data.message,
                })
                setTimeout(()=>{
                    this.setState({
                        isModalOpen:false,
                    })
                    this.props.history.goBack()
                },1000)
            }
        } catch(err){
            console.log(err)
        }
    }
    openMembershipModal = () =>{
        this.setState({
            showMembershipModal:!this.state.showMembershipModal
        })
    }
    changeMembership = async (_membershipType) =>{
        try{
            let params = {
                client_id:this.props.match.params._id,
                membershipType:_membershipType
            }
            let url = `${process.env.REACT_APP_BASE_API_URL}/management/clients/changeMembership`
            let config = {
                headers:{
                    authorization:'Bearer ' + localStorage.getItem('token')
                }
            }
            const updateMembership = await axios.post(url,params, config)
            if(updateMembership.status == 200){
                this.setState({
                    isSuccess:true,
                    clientInfo:{...this.state.clientInfo, membershipType:_membershipType}
                })
            }
        }catch(err){
            this.setState({
                hasError:true,
                message:err.response.data.message
            })
            console.log(err)
        }
    }

    render() {
        console.log(this.state.address)
        return (
            this.state.isLoading ? 
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            :
                <div className="contentContainer">
                    <Header header={'Clients'} user={this.context.user} openModal={this.openReferenceModal} showBack={true} buttonTitle={'Generate Reference Code'}/>
                    <div className='clientDetailContainer'>
                        <div className='infoContainer' style={{paddingBottom:15}}>
                            <span className='title'>Personal Information</span>
                            <div style={{flexDirection:'row', display:'flex'}}>
                                {this.state.clientInfo.profile_image != null ?
                                    <img src={this.state.clientInfo.profile_image} className="profileImage"/>:
                                    <img src={require('../assets/profile.png')} className="profileImage"/>}
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <span className='name'>{this.state.clientInfo.name}</span>
                                    <span>{this.state.clientInfo.email}</span>
                                    <span>{this.state.clientInfo.phone_number}</span>
                                    <span>Membership Type: <span>{this.state.clientInfo.membershipType}</span></span>
                                    {/* <span>Registration Status: <span>{this.state.clientInfo.registration_status}</span></span> */}
                                </div>
                                <div style={{justifySelf:'flex-end', alignSelf:'flex-end', display:'flex', flex:1, justifyItems:'flex-end', alignItems:'flex-end'}}>
                                    <span className='changeMembership' onClick={()=>{this.openMembershipModal()}}>Change membership</span>
                                </div>
                            </div>
                        </div>
                        <div className='infoContainer bioFix'>
                            <span className='title'>Family Bio</span>
                            <span className='name'>{this.state.clientInfo.bio}</span>
                        </div>
                        <div className='infoContainer bioFix'>
                            <span className='title'>Medical Bio</span>
                            <span className='name'>{this.state.clientInfo.medicalInformation}</span>
                        </div>
                        {this.state.address != null ?
                        <div className='infoContainer bioFix' style={{minHeight:75}}>
                            <span className='title'>Address</span>
                            <span>{this.state.address.address_line_1}{' '}{this.state.address.address_line_2}</span>
                            <span>{this.state.address.post_code} - {this.state.address.city}</span>
                        </div> : null}
                        <div className='infoContainer bookings'>
                            <span className='title'>Bookings</span>
                            
                            {this.state.bookings.map(booking=>
                            booking != null ?
                            <Link 
                                to={{
                                    pathname: `/bookingDetails/${booking.booking_id}`,
                                }}
                                style={{textDecoration:'none', color:'inherit'}}
                            >
                                <div key={booking.booking_id} className="bookingItem">
                                    <img src={booking.nanny_photo_url} width={75} height={75}/>
                                    <span>{booking.nanny_name}</span>
                                    {booking.isOvernight ?
                                        <div style={{flexDirection:'column', display:'flex', minWidth:250}}>
                                            <span>{booking.service_name} Overnight {booking.isRepeating ? ' Repeat Booking' : ''} </span>
                                            <span>From: {moment(booking.startTime).format('DD MMM ddd')} - {moment(booking.startTime).format('HH:mm')}</span>
                                            <span>To: {moment(booking.endTime).format('DD MMM ddd')} - {moment(booking.endTime).format('HH:mm')}</span>
                                        </div>
                                    :
                                        <div style={{display:'flex', flexDirection:'column', minWidth:250}}>
                                            <span>{booking.service_name}{booking.isRepeating ? ' Repeat Booking' : ''}</span>
                                            <span>{moment(booking.startTime).format('DD MMM ddd')} - {moment(booking.startTime).format('HH:mm')} - {moment(booking.endTime).format('HH:mm')}</span>
                                        </div>
                                    }
                                    <div style={{display:'flex', flexDirection:'column', minWidth:250}}>
                                        <span>Status</span>
                                        <span>{booking.status} {booking.status != 'Awaiting confirmation' ? `by ${booking?.updated_by}` : ``}</span>
                                        <span>Cancellation Note: {booking.cancellationNote}</span>
                                    </div>
                                </div> </Link> : null 
                            )}
                        </div>
                        <div style={{height:100, alignItems:'center'}}>
                            <button type="submit" name="cancel" id="cancel" className="saveButton" onClick={()=>{this.changeActiveStatus()}}>{this.state.clientInfo.status == 'Active' ? 'Cancel Membership' : 'Reactivate Membership'}</button>
                        </div>
                    </div>
                    <MembershipModal open={this.state.showMembershipModal} close={this.openMembershipModal} currentMembership={this.state.clientInfo.membershipType} change={this.changeMembership} hasError={this.state.hasError} isSuccess={this.state.isSuccess} message={this.state.message}/>
                </div>
        )
    }
}
export default withRouter(ClientDetails)